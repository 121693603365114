<template>
  <b-container fluid="md">
    <div v-show="admnodft">
      <b-card class="xnshadow mb-5" header-tag="header">
        <template v-slot:header>
          <h5 class="mb-0">
            <b-icon icon="power"></b-icon> 错误提示：
          </h5>
        </template>
        <b-card-text>
          <b-alert variant="danger" show>{{$t('message.cfmdefault')}}</b-alert>
        </b-card-text>
      </b-card>
    </div>
    <div v-show="!admnodft">
      <b-card class="xnshadow mb-5" header-tag="header">
        <template v-slot:header>
          <h5 class="mb-0">
            <b-icon icon="card-list"></b-icon> 优惠券设置说明
          </h5>
        </template>
        <b-card-text>
        最多设置五个档，每一档的第一项为充值金额，第二项为赠送优惠券的数目，第三项为单张优惠券的金额。<br/>
        第一档金额固定为0元，表示新客户赠送，其中赠送优惠券数目和单张优惠券金额设置为0表示没有此项活动。<br/>
        新客注册绑手机时自动赠送，充值成功后自动赠送。有效期为一年，自领取之日起一年内使用有效。<br/>
        每次充电最多只能使用一张优惠抵用券。<br/>
        注意：启用金额设置仅在领券时有效。已经领取的优惠券不受重新设置启用金额的影响。<br/>
        重要提示‼️：汽车充电优惠与电瓶车充电优惠券不能同时设置。<br/>
        </b-card-text>
      </b-card>
      <b-card class="xnshadow mb-5" header-tag="header">
        <template v-slot:header>
          <h3 class="mb-0">
            <b-icon icon="tools"></b-icon> 优惠券最低启用金额
          </h3>
        </template>
        <b-card-text>
          <b-row cols="2" cols-xs="2" class="mb-2 pl-3">
            <span>电瓶车启用金额</span>
            <span>汽车服务费启用</span>
          </b-row>
          <b-row cols="2" cols-xs="2" class="mb-2 pl-3">
            <b-input-group append="元" class="mb-2 pr-3">
              <b-form-input v-model="svcbillstart[0]" v-b-tooltip.hover title="单车充电消费金额低于该值,不使用优惠券。领券后重新设置无效。"></b-form-input>
            </b-input-group>
            <b-input-group append="元" class="mb-2 pr-3">
              <b-form-input v-model="svcbillstart[1]" v-b-tooltip.hover title="汽车充电消费服务费达到该值,自动抵扣一张优惠券。领券后重新设置无效。"></b-form-input>
            </b-input-group>
          </b-row>
        </b-card-text>
      </b-card>
      <b-card class="xnshadow mb-5" header-tag="header">
        <template v-slot:header>
          <h3 class="mb-0">
            <b-icon icon="tools"></b-icon> 电瓶车优惠券(用于充电费用抵扣)
          </h3>
        </template>
        <b-card-text>
          <b-row cols="3" cols-xs="3" class="mb-2 pl-3">
            <span>充值金额(元)</span>
            <span>赠送数目(张)</span>
            <span>每券面额(元)</span>
          </b-row>
          <b-row cols="3" cols-xs="3" class="mb-2 pl-3">
            <b-input-group append="￥" class="mb-2 pr-3">
              <b-form-input disabled="true" value="新客绑手机领券"></b-form-input>
            </b-input-group>
            <b-input-group append="张" class="mb-2 pr-3">
              <b-form-input v-model="svcbill0[1]" v-b-tooltip.hover title="填0表示没有新客优惠"></b-form-input>
            </b-input-group>
            <b-input-group append="元" class="mb-2 pr-3">
              <b-form-input v-model="svcbill0[2]" v-b-tooltip.hover title="券面额不宜过大,不要超过启用金额"></b-form-input>
            </b-input-group>
          </b-row>
          <b-row cols="3" cols-xs="3" class="mb-2 pl-3">
            <b-input-group append="元" class="mb-2 pr-3">
              <b-form-input v-model="svcbill0[3]" v-b-tooltip.hover title="第一档：单车充值达到该金额自动获得优惠券。"></b-form-input>
            </b-input-group>
            <b-input-group append="张" class="mb-2 pr-3">
              <b-form-input v-model="svcbill0[4]" v-b-tooltip.hover title="赠送优惠券数目"></b-form-input>
            </b-input-group>
            <b-input-group append="元" class="mb-2 pr-3">
              <b-form-input v-model="svcbill0[5]" v-b-tooltip.hover title="每张优惠券的金额(单位元,支持小数)"></b-form-input>
            </b-input-group>
          </b-row>
          <b-row cols="3" cols-xs="3" class="mb-2 pl-3">
            <b-input-group append="元" class="mb-2 pr-3">
              <b-form-input v-model="svcbill0[6]" v-b-tooltip.hover title="第二档：单车充值达到该金额自动获得优惠券。"></b-form-input>
            </b-input-group>
            <b-input-group append="张" class="mb-2 pr-3">
              <b-form-input v-model="svcbill0[7]" v-b-tooltip.hover title="赠送优惠券数目(第二档)"></b-form-input>
            </b-input-group>
            <b-input-group append="元" class="mb-2 pr-3">
              <b-form-input v-model="svcbill0[8]" v-b-tooltip.hover title="每张优惠券的金额(精确到分)"></b-form-input>
            </b-input-group>
          </b-row>
          <b-row cols="3" cols-xs="3" class="mb-2 pl-3">
            <b-input-group append="元" class="mb-2 pr-3">
              <b-form-input v-model="svcbill0[9]" v-b-tooltip.hover title="第三档：单车充值达到该金额自动获得优惠券。"></b-form-input>
            </b-input-group>
            <b-input-group append="张" class="mb-2 pr-3">
              <b-form-input v-model="svcbill0[10]" v-b-tooltip.hover title="赠送优惠券数目(第三档)"></b-form-input>
            </b-input-group>
            <b-input-group append="元" class="mb-2 pr-3">
              <b-form-input v-model="svcbill0[11]" v-b-tooltip.hover title="每张优惠券的金额(元,可填两位小数)"></b-form-input>
            </b-input-group>
          </b-row>
          <b-row cols="3" cols-xs="3" class="mb-2 pl-3">
            <b-input-group append="元" class="mb-2 pr-3">
              <b-form-input v-model="svcbill0[12]" v-b-tooltip.hover title="第四档：每一档应该比前一档金额高。"></b-form-input>
            </b-input-group>
            <b-input-group append="张" class="mb-2 pr-3">
              <b-form-input v-model="svcbill0[13]" v-b-tooltip.hover title="赠送优惠券数目(第四档)"></b-form-input>
            </b-input-group>
            <b-input-group append="元" class="mb-2 pr-3">
              <b-form-input v-model="svcbill0[14]" v-b-tooltip.hover title="每张优惠券的金额(元,支持小数)"></b-form-input>
            </b-input-group>
          </b-row>
        </b-card-text>
      </b-card>
      <b-card class="xnshadow mb-5" header-tag="header">
        <template v-slot:header>
          <h3 class="mb-0">
            <b-icon icon="tools"></b-icon> 汽车充电优惠券(仅用于服务费抵扣)
          </h3>
        </template>
        <b-card-text>
          <b-row cols="3" cols-xs="3" class="mb-2 pl-3">
            <span>充值金额(元)</span>
            <span>赠送数目(张)</span>
            <span>每券面额(元)</span>
          </b-row>
          <b-row cols="3" cols-xs="3" class="mb-2 pl-3">
            <b-input-group append="￥" class="mb-2 pr-3">
              <b-form-input disabled="true" value="新客绑手机领券"></b-form-input>
            </b-input-group>
            <b-input-group append="张" class="mb-2 pr-3">
              <b-form-input v-model="svcbill1[1]" v-b-tooltip.hover title="填0表示没有新客优惠"></b-form-input>
            </b-input-group>
            <b-input-group append="元" class="mb-2 pr-3">
              <b-form-input v-model="svcbill1[2]" v-b-tooltip.hover title="券面额不宜过大,不要超过启用金额"></b-form-input>
            </b-input-group>
          </b-row>
          <b-row cols="3" cols-xs="3" class="mb-2 pl-3">
            <b-input-group append="元" class="mb-2 pr-3">
              <b-form-input v-model="svcbill1[3]" v-b-tooltip.hover title="第一档：充值金额达到该数值可自动获得汽车服务费抵扣券。"></b-form-input>
            </b-input-group>
            <b-input-group append="张" class="mb-2 pr-3">
              <b-form-input v-model="svcbill1[4]" v-b-tooltip.hover title="赠送服务费抵扣券数目(第一档)"></b-form-input>
            </b-input-group>
            <b-input-group append="元" class="mb-2 pr-3">
              <b-form-input v-model="svcbill1[5]" v-b-tooltip.hover title="每张优惠券的金额(单位元,支持小数)"></b-form-input>
            </b-input-group>
          </b-row>
          <b-row cols="3" cols-xs="3" class="mb-2 pl-3">
            <b-input-group append="元" class="mb-2 pr-3">
              <b-form-input v-model="svcbill1[6]" v-b-tooltip.hover title="第二档：充值金额达到该数值可自动获得汽车服务费抵扣券。"></b-form-input>
            </b-input-group>
            <b-input-group append="张" class="mb-2 pr-3">
              <b-form-input v-model="svcbill1[7]" v-b-tooltip.hover title="赠送服务费抵扣券数目(第二档)"></b-form-input>
            </b-input-group>
            <b-input-group append="元" class="mb-2 pr-3">
              <b-form-input v-model="svcbill1[8]" v-b-tooltip.hover title="每张优惠券的金额(精确到分)"></b-form-input>
            </b-input-group>
          </b-row>
          <b-row cols="3" cols-xs="3" class="mb-2 pl-3">
            <b-input-group append="元" class="mb-2 pr-3">
              <b-form-input v-model="svcbill1[9]" v-b-tooltip.hover title="第三档：充值金额达到该数值可自动获得汽车服务费抵扣券。"></b-form-input>
            </b-input-group>
            <b-input-group append="张" class="mb-2 pr-3">
              <b-form-input v-model="svcbill1[10]" v-b-tooltip.hover title="赠送服务费抵扣券数目(第三档)"></b-form-input>
            </b-input-group>
            <b-input-group append="元" class="mb-2 pr-3">
              <b-form-input v-model="svcbill1[11]" v-b-tooltip.hover title="每张优惠券的金额(元,可填两位小数)"></b-form-input>
            </b-input-group>
          </b-row>
          <b-row cols="3" cols-xs="3" class="mb-2 pl-3">
            <b-input-group append="元" class="mb-2 pr-3">
              <b-form-input v-model="svcbill1[12]" v-b-tooltip.hover title="第四档：每一档应该比前一档金额高。"></b-form-input>
            </b-input-group>
            <b-input-group append="张" class="mb-2 pr-3">
              <b-form-input v-model="svcbill1[13]" v-b-tooltip.hover title="赠送服务费抵扣券数目(第四档)"></b-form-input>
            </b-input-group>
            <b-input-group append="元" class="mb-2 pr-3">
              <b-form-input v-model="svcbill1[14]" v-b-tooltip.hover title="每张优惠券的金额(元,支持小数)"></b-form-input>
            </b-input-group>
          </b-row>
        </b-card-text>
      </b-card>
      <b-form-group class="mb-2 mt-4">
        <b-button block pill size="lg" variant="success" @click="setsave">立即保存设置</b-button>
      </b-form-group>
    </div>
    <b-modal v-model="modalshow" no-close-on-backdrop no-close-on-esc hide-header ok-only :ok-title="$t('message.btn_ok')">{{modalmsg}}</b-modal>
  </b-container>
</template>

<script>
  export default {
    name: 'svcbillset',
    mounted() {
      this.fetchData();
    },
    data() {
      return {
        admnodft:false,
        modalshow:false,
        modalmsg: '',
        svcbill0: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
        svcbill1: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
        svcbillstart: [0,0]
      };
    },
    methods: {
      fetchData() {
        let user = JSON.parse(localStorage.getItem('user'));
        let dftapp = localStorage.getItem('dftapp');
        if (user.isadmin && !dftapp) {
          this.admnodft = true;
        } else {
          this.admnodft = false;
        }
        if (!this.admnodft) {
          let token = localStorage.getItem('token');
          let theapi = '/svcbillsetread?tm=' + new Date().getTime() + '&token=' + token;
          this.axios.get(theapi).then(axresp => {
            if (axresp.status == 200) {
              this.svcbill0 = axresp.data.svcbill0.split(',');
              this.svcbill1 = axresp.data.svcbill1.split(',');
              this.svcbillstart = axresp.data.svcbillstart.split(',');
            }
          });
        }
      },
      setsave() {
        let bill0 = this.svcbill0;
        let bill1 = this.svcbill1;
        let billstart = this.svcbillstart;
        for (var i=0;i<bill0.length;i++) {
          if (i%3==2) {
            bill0[i] = Number(parseFloat(bill0[i]).toFixed(2));
          } else {
            bill0[i] = parseInt(bill0[i]);
          }
        }
        for (var j=0;j<bill1.length;j++) {
          if (i%3==2) {
            bill1[j] = Number(parseFloat(bill1[j]).toFixed(2));
          } else {
            bill1[j] = parseInt(bill1[j]);
          }
        }
        for (var k=0;k<billstart.length;k++) {
          billstart[k] = parseInt(billstart[k]);
        }
        var errorid = 0;
        if (bill0[1]+bill0[2]>0 && bill0[1]*bill0[2]==0) {
          errorid = 1;
        }
        if (errorid==0 && bill0[3]+bill0[4]+bill0[5]>0 && bill0[3]*bill0[4]*bill0[5]==0) {
          errorid = 2;
        }
        if (errorid==0 && bill0[6]+bill0[7]+bill0[8]>0 && bill0[6]*bill0[7]*bill0[8]==0) {
          errorid = 3;
        }
        if (errorid==0 && bill0[9]+bill0[10]+bill0[11]>0 && bill0[9]*bill0[10]*bill0[11]==0) {
          errorid = 4;
        }
        if (errorid==0 && bill0[12]+bill0[13]+bill0[14]>0 && bill0[12]*bill0[13]*bill0[14]==0) {
          errorid = 5;
        }
        if (errorid==0 && bill0[12]>0 && bill0[3]*bill0[6]*bill0[9]==0) {
          errorid = 6;
        }
        if (errorid==0 && bill0[9]>0 && bill0[3]*bill0[6]==0) {
          errorid = 7;
        }
        if (errorid==0 && bill0[6]>0 && bill0[3]==0) {
          errorid = 8;
        }
        if (errorid==0 && bill1[3]+bill1[4]+bill1[5]>0 && bill1[3]*bill1[4]*bill1[5]==0) {
          errorid = 9;
        }
        if (errorid==0 && bill1[6]+bill1[7]+bill1[8]>0 && bill1[6]*bill1[7]*bill1[8]==0) {
          errorid = 10;
        }
        if (errorid==0 && bill1[9]+bill1[10]+bill1[11]>0 && bill1[9]*bill1[10]*bill1[11]==0) {
          errorid = 11;
        }
        if (errorid==0 && bill1[12]+bill1[13]+bill1[14]>0 && bill1[12]*bill1[13]*bill1[14]==0) {
          errorid = 12;
        }
        if (errorid==0 && bill1[12]>0 && bill1[3]*bill1[6]*bill1[9]==0) {
          errorid = 13;
        }
        if (errorid==0 && bill1[9]>0 && bill1[3]*bill1[6]==0) {
          errorid = 14;
        }
        if (errorid==0 && bill1[6]>0 && bill1[3]==0) {
          errorid = 15;
        }
        if (errorid==0 && bill0[12]>0 && (bill0[3]>bill0[6] || bill0[3]>bill0[9] || bill0[3]>bill0[12] || bill0[6]>bill0[9] || bill0[6]>bill0[12] || bill0[9]>bill0[12])) {
          errorid = 16;
        }
        if (errorid==0 && bill0[9]>0 && (bill0[3]>bill0[6] || bill0[3]>bill0[9] || bill0[6]>bill0[9])) {
          errorid = 16;
        }
        if (errorid==0 && bill0[6]>0 && (bill0[3]>bill0[6])) {
          errorid = 16;
        }
        if (errorid==0 && bill1[12]>0 && (bill1[3]>bill1[6] || bill1[3]>bill1[9] || bill1[3]>bill1[12] || bill1[6]>bill1[9] || bill1[6]>bill1[12] || bill1[9]>bill1[12])) {
          errorid = 19;
        }
        if (errorid==0 && bill1[9]>0 && (bill1[3]>bill1[6] || bill1[3]>bill1[9] || bill1[6]>bill1[9])) {
          errorid = 19;
        }
        if (errorid==0 && bill1[6]>0 && (bill1[3]>bill1[6])) {
          errorid = 19;
        }
        if (errorid==0) {
          if (bill0[1]+bill1[1]>0 && bill0[1]*bill1[1]>0) {
            errorid = 20;
          }
          if (bill0[4]+bill1[4]>0 && bill0[4]*bill1[4]>0) {
            errorid = 20;
          }
        }
        let token = localStorage.getItem('token');
        let theapi = '/svcbillsetsave?tm=' + new Date().getTime() + '&token=' + token + 
          '&svcbill0=' + bill0.join(',')+
          '&svcbill1='+bill1.join(',')+
          '&svcbillstart='+billstart.join(',');
        switch (errorid) {
        case 0:
          this.axios.get(theapi).then((axresp) => {
            if (axresp.status == 200) {
              this.fetchData();
            }
          });
          break;
        case 1:
          this.modalmsg = '单车新客券设置不正确!';
          this.modalshow = true;
          break;
        case 2:
          this.modalmsg = '单车第一档设置有误!';
          this.modalshow = true;
          break;
        case 3:
          this.modalmsg = '单车第二档设置有误!';
          this.modalshow = true;
          break;
        case 4:
          this.modalmsg = '单车第三档设置有误!';
          this.modalshow = true;
          break;
        case 5:
          this.modalmsg = '单车第四档设置有误!';
          this.modalshow = true;
          break;
        case 6:
          this.modalmsg = '单车第四档前面不能有0!';
          this.modalshow = true;
          break;
        case 7:
          this.modalmsg = '单车第三档前面不能有0!';
          this.modalshow = true;
          break;
        case 8:
          this.modalmsg = '单车第二档前面不能有0';
          this.modalshow = true;
          break;
        case 9:
          this.modalmsg = '汽车第1档设置有误!';
          this.modalshow = true;
          break;
        case 10:
          this.modalmsg = '汽车第2档设置有误!';
          this.modalshow = true;
          break;
        case 11:
          this.modalmsg = '汽车第3档设置有误!';
          this.modalshow = true;
          break;
        case 12:
          this.modalmsg = '汽车第4档设置有误!';
          this.modalshow = true;
          break;
        case 13:
          this.modalmsg = '汽车第4档前面不能有0!';
          this.modalshow = true;
          break;
        case 14:
          this.modalmsg = '汽车第3档前面不能有0!';
          this.modalshow = true;
          break;
        case 15:
          this.modalmsg = '汽车第2档前面不能有0!';
          this.modalshow = true;
          break;
        case 16:
          this.modalmsg = '前一档应该比后一档小!';
          this.modalshow = true;
          break;
        case 19:
          this.modalmsg = '前一档应该比后一档小!';
          this.modalshow = true;
          break;
        case 20:
          this.modalmsg = '汽车和单车充电不能同时启用优惠券';
          this.modalshow = true;
          break;
        default:
          break;
        }
      },
    }
  }
</script>
